exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-awards-index-js": () => import("./../../../src/pages/awards/index.js" /* webpackChunkName: "component---src-pages-awards-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-careers-designer-js": () => import("./../../../src/pages/careers/designer.js" /* webpackChunkName: "component---src-pages-careers-designer-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-laravel-js": () => import("./../../../src/pages/careers/laravel.js" /* webpackChunkName: "component---src-pages-careers-laravel-js" */),
  "component---src-pages-careers-reactinfo-js": () => import("./../../../src/pages/careers/reactinfo.js" /* webpackChunkName: "component---src-pages-careers-reactinfo-js" */),
  "component---src-pages-client-js": () => import("./../../../src/pages/client.js" /* webpackChunkName: "component---src-pages-client-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-audit-js": () => import("./../../../src/pages/services/audit.js" /* webpackChunkName: "component---src-pages-services-audit-js" */),
  "component---src-pages-services-cyber-security-api-security-js": () => import("./../../../src/pages/services/cyberSecurity/apiSecurity.js" /* webpackChunkName: "component---src-pages-services-cyber-security-api-security-js" */),
  "component---src-pages-services-cyber-security-database-security-js": () => import("./../../../src/pages/services/cyberSecurity/databaseSecurity.js" /* webpackChunkName: "component---src-pages-services-cyber-security-database-security-js" */),
  "component---src-pages-services-cyber-security-mobile-app-security-js": () => import("./../../../src/pages/services/cyberSecurity/mobileAppSecurity.js" /* webpackChunkName: "component---src-pages-services-cyber-security-mobile-app-security-js" */),
  "component---src-pages-services-cyber-security-secure-code-review-js": () => import("./../../../src/pages/services/cyberSecurity/secureCodeReview.js" /* webpackChunkName: "component---src-pages-services-cyber-security-secure-code-review-js" */),
  "component---src-pages-services-cyber-security-thick-client-security-js": () => import("./../../../src/pages/services/cyberSecurity/thickClientSecurity.js" /* webpackChunkName: "component---src-pages-services-cyber-security-thick-client-security-js" */),
  "component---src-pages-services-cyber-security-web-app-security-js": () => import("./../../../src/pages/services/cyberSecurity/webAppSecurity.js" /* webpackChunkName: "component---src-pages-services-cyber-security-web-app-security-js" */),
  "component---src-pages-services-digital-marketing-service-india-branding-service-india-js": () => import("./../../../src/pages/services/digitalMarketing-service-india/branding-service-india.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-service-india-branding-service-india-js" */),
  "component---src-pages-services-digital-marketing-service-india-js": () => import("./../../../src/pages/services/digitalMarketing-service-india.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-service-india-js" */),
  "component---src-pages-services-digital-marketing-service-india-search-engine-optimization-service-india-js": () => import("./../../../src/pages/services/digitalMarketing-service-india/searchEngineOptimization-service-india.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-service-india-search-engine-optimization-service-india-js" */),
  "component---src-pages-services-digital-marketing-service-india-social-media-marketing-service-india-js": () => import("./../../../src/pages/services/digitalMarketing-service-india/socialMediaMarketing-service-india.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-service-india-social-media-marketing-service-india-js" */),
  "component---src-pages-services-doc-accessibility-accessible-excel-js": () => import("./../../../src/pages/services/docAccessibility/accessibleExcel.js" /* webpackChunkName: "component---src-pages-services-doc-accessibility-accessible-excel-js" */),
  "component---src-pages-services-doc-accessibility-accessible-pdf-forms-js": () => import("./../../../src/pages/services/docAccessibility/accessiblePDFForms.js" /* webpackChunkName: "component---src-pages-services-doc-accessibility-accessible-pdf-forms-js" */),
  "component---src-pages-services-doc-accessibility-accessible-pdfua-js": () => import("./../../../src/pages/services/docAccessibility/accessiblePDFUA.js" /* webpackChunkName: "component---src-pages-services-doc-accessibility-accessible-pdfua-js" */),
  "component---src-pages-services-doc-accessibility-accessible-ppt-js": () => import("./../../../src/pages/services/docAccessibility/accessiblePPT.js" /* webpackChunkName: "component---src-pages-services-doc-accessibility-accessible-ppt-js" */),
  "component---src-pages-services-doc-accessibility-accessible-word-js": () => import("./../../../src/pages/services/docAccessibility/accessibleWord.js" /* webpackChunkName: "component---src-pages-services-doc-accessibility-accessible-word-js" */),
  "component---src-pages-services-doc-accessibility-doc-accessibility-service-india-js": () => import("./../../../src/pages/services/docAccessibility/docAccessibility-service-india.js" /* webpackChunkName: "component---src-pages-services-doc-accessibility-doc-accessibility-service-india-js" */),
  "component---src-pages-services-doc-website-analysis-js": () => import("./../../../src/pages/services/docWebsiteAnalysis.js" /* webpackChunkName: "component---src-pages-services-doc-website-analysis-js" */),
  "component---src-pages-services-ecommerce-development-service-india-js": () => import("./../../../src/pages/services/ecommerceDevelopment-service-india.js" /* webpackChunkName: "component---src-pages-services-ecommerce-development-service-india-js" */),
  "component---src-pages-services-indesign-accessibility-service-india-index-js": () => import("./../../../src/pages/services/indesignAccessibility-service-india/index.js" /* webpackChunkName: "component---src-pages-services-indesign-accessibility-service-india-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobile-development-service-india-js": () => import("./../../../src/pages/services/mobileDevelopment-service-india.js" /* webpackChunkName: "component---src-pages-services-mobile-development-service-india-js" */),
  "component---src-pages-services-product-development-service-india-js": () => import("./../../../src/pages/services/productDevelopment-service-india.js" /* webpackChunkName: "component---src-pages-services-product-development-service-india-js" */),
  "component---src-pages-services-testing-js": () => import("./../../../src/pages/services/testing.js" /* webpackChunkName: "component---src-pages-services-testing-js" */),
  "component---src-pages-services-testings-functional-testing-service-india-js": () => import("./../../../src/pages/services/testings/functionalTesting-service-india.js" /* webpackChunkName: "component---src-pages-services-testings-functional-testing-service-india-js" */),
  "component---src-pages-services-testings-performance-testing-service-india-js": () => import("./../../../src/pages/services/testings/performanceTesting-service-india.js" /* webpackChunkName: "component---src-pages-services-testings-performance-testing-service-india-js" */),
  "component---src-pages-services-testings-security-testing-service-india-js": () => import("./../../../src/pages/services/testings/securityTesting-service-india.js" /* webpackChunkName: "component---src-pages-services-testings-security-testing-service-india-js" */),
  "component---src-pages-services-testings-usability-testing-service-india-js": () => import("./../../../src/pages/services/testings/usabilityTesting-service-india.js" /* webpackChunkName: "component---src-pages-services-testings-usability-testing-service-india-js" */),
  "component---src-pages-services-u-iux-design-service-india-js": () => import("./../../../src/pages/services/uIUXDesign-service-india.js" /* webpackChunkName: "component---src-pages-services-u-iux-design-service-india-js" */),
  "component---src-pages-services-web-accessibility-service-india-index-js": () => import("./../../../src/pages/services/webAccessibility-service-india/index.js" /* webpackChunkName: "component---src-pages-services-web-accessibility-service-india-index-js" */),
  "component---src-pages-services-web-development-service-india-js": () => import("./../../../src/pages/services/webDevelopment-service-india.js" /* webpackChunkName: "component---src-pages-services-web-development-service-india-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

